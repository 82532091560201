import Vue from "vue";
import VueRouter from "vue-router";
import XiuZhen from "../components/XiuZhen";
import JuLingZhen from "../components/JuLingZhen";
import Home from "../components/Home";
import DuJieTime from "../components/DuJieTime";

import JuLingZhen2 from "../components/JuLingZhen2";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/XiuZhen",
  },
  {
    path: "/JuLingZhen",
    name: "JuLingZhen",
    component: JuLingZhen,
  },
  {
    path: "/JuLingZhen2",
    name: "JuLingZhen2",
    component: JuLingZhen2,
  },
  {
    path: "/XiuZhen",
    name: "XiuZhen",
    component: XiuZhen,
  },
  {
    path: "/DuJieTime",
    name: "DuJieTime",
    component: DuJieTime,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
