<template>
  <el-card shadow="hover" @click.native="jump">{{ name }}</el-card>
</template>

<script>
export default {
  props: ["name", "com"],
  methods: {
    jump() {
      //   console.log(this.url);
      console.log(this.com);
      this.$router.push({ name: this.com });
    },
  },
};
</script>

<style>
</style>