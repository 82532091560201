<template>
  <el-row>
    <el-col :span="12">
      <xz-button :name="'聚灵推演器'" :com="'JuLingZhen'"></xz-button>
    </el-col>
    <el-col :span="12">
      <xz-button :name="'渡劫须几时?'" :com="'DuJieTime'"></xz-button>
    </el-col>
    <el-col :span="12">
      <xz-button :name="'聚灵推演器2'" :com="'JuLingZhen2'"></xz-button>
    </el-col>
  </el-row>
</template>

<script>
import XzButton from "./XzButton.vue";
export default {
  components: { XzButton },
  name: "XiuZhen",
  data() {
    return {
      test: "我是一只狼",
    };
  },
};
</script>

<style>
</style>