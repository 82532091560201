<template>
  <el-form
    ref="$form"
    :model="model"
    label-position="left"
    label-width="100px"
    size="small"
  >
    <el-form-item :rules="rules.Need" prop="Need" label="升级所需">
      <el-input
        v-model="model.Need"
        placeholder="升级所需"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.Have" prop="Have" label="当前拥有">
      <el-input
        v-model="model.Have"
        placeholder="当前拥有"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.LMax" prop="LMax" label="吸收上限">
      <el-input
        v-model="model.LMax"
        placeholder="吸收上限"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.LMin" prop="LMin" label="吸收下限">
      <el-input
        v-model="model.LMin"
        placeholder="吸收下限"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item prop="Add" label="额外吸收">
      <el-input v-model="model.Add" placeholder="额外吸收" clearable></el-input>
    </el-form-item>

    <el-row :gutter="20" type="flex" justify="start" align="top" tag="div">
      <el-col :span="12" :offset="0" :push="0" :pull="0" tag="div">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          :style="{ width: '100%' }"
          @click="submit"
          >计算</el-button
        >
      </el-col>
      <el-col :span="12" :offset="0" :push="0" :pull="0" tag="div">
        <el-button
          size="small"
          icon="el-icon-refresh-left"
          :style="{ width: '100%' }"
          @click="forms.forEach((form) => $refs[form].resetFields())"
          >重 置</el-button
        >
      </el-col>
    </el-row>
    <el-dialog title="预计耗时" :visible.sync="dialogVisible" width="85%">
      <el-divider content-position="center">
        {{ res.format }}<br />({{ res.time.toFixed(2) }}s)</el-divider
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
function formateTime(time) {
  const h = parseInt(time / 3600);
  const minute = parseInt((time / 60) % 60);
  const second = Math.ceil(time % 60);

  const hours = h < 10 ? "0" + h : h;
  const formatSecond = second > 59 ? 59 : second;
  return `${hours > 0 ? `${hours}:` : ""}${
    minute < 10 ? "0" + minute : minute
  }:${formatSecond < 10 ? "0" + formatSecond : formatSecond}`;
}

function solve(Need, Have, LMax, LMin, Add) {
  let c = parseFloat(Need) - parseFloat(Have);
  let t_res =
    (c * 10) / (parseFloat(LMax) + parseFloat(LMin) + 2 * parseFloat(Add));
  return { time: t_res, format: formateTime(t_res) };
  // alert(formateTime(t_res));
}

export default {
  //   name: "form",
  components: {},
  data() {
    return {
      forms: ["$form"],
      dialogVisible: false,
      res: {
        time: 0,
      },
      model: {
        Need: "",
        Have: "",
        LMax: "",
        LMin: "",
        Add: 0,
      },
      rules: {
        Need: [
          {
            required: true,
            message: "升级所需不能为空",
            trigger: "blur",
          },
        ],
        Have: [
          {
            required: true,
            message: "当前拥有不能为空",
            trigger: "blur",
          },
        ],
        LMax: [
          {
            required: true,
            message: "吸收上限不能为空",
            trigger: "blur",
          },
        ],
        LMin: [
          {
            required: true,
            message: "吸收下限不能为空",
            trigger: "blur",
          },
        ],
      },
      submit_loading: false,
    };
  },
  computed: {},
  methods: {
    submit() {
      Promise.all(this.forms.map((form) => this.$refs[form].validate())).then(
        () => {
          this.submit_loading = true;
          // TODO demo

          this.res = solve(
            this.model.Need,
            this.model.Have,
            this.model.LMax,
            this.model.LMin,
            this.model.Add
          );
          this.dialogVisible = true;
          setTimeout(() => {
            this.submit_loading = false;
          }, 3000);
        }
      );
    },
  },
  created() {},
};
</script>

<style>
</style>