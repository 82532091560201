<template>
  <router-view> </router-view>
</template>

<script>
export default {
  name: "Home",
  created() {
    // this.$router.push("/XiuZhen");
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>