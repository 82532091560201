<template>
  <el-form
    ref="$form"
    :model="model"
    label-position="left"
    label-width="100px"
    size="small"
  >
    <el-form-item :rules="rules.Fooder" prop="Fooder" label="食物劳工">
      <el-input
        v-model="model.Fooder"
        placeholder="食物劳工"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.Timber" prop="Timber" label="现有木头">
      <el-input
        v-model="model.Timber"
        placeholder="现有木头"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.Grass" prop="Grass" label="现有药草">
      <el-input
        v-model="model.Grass"
        placeholder="现有药草"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.DT_grass" prop="DT_grass" label="道童产草">
      <el-input
        v-model="model.DT_grass"
        placeholder="道童产草"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.DT_iron" prop="DT_iron" label="道童产铁">
      <el-input
        v-model="model.DT_iron"
        placeholder="道童产铁"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item :rules="rules.Iron" prop="Iron" label="现有矿石">
      <el-input
        v-model="model.Iron"
        placeholder="现有矿石"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item
      :rules="rules.Timber_need"
      prop="Timber_need"
      label="所需木头"
    >
      <el-input
        v-model="model.Timber_need"
        placeholder="所需木头"
        clearable
      ></el-input>
    </el-form-item>
    <el-row :gutter="20" type="flex" justify="start" align="top" tag="div">
      <el-col :span="12" :offset="0" :push="0" :pull="0" tag="div">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          :style="{ width: '100%' }"
          @click="submit"
          >计算</el-button
        >
      </el-col>
      <el-col :span="12" :offset="0" :push="0" :pull="0" tag="div">
        <el-button
          size="small"
          icon="el-icon-refresh-left"
          :style="{ width: '100%' }"
          @click="forms.forEach((form) => $refs[form].resetFields())"
          >重 置</el-button
        >
      </el-col>
    </el-row>
    <el-dialog title="预计耗时" :visible.sync="dialogVisible" width="85%">
      <el-divider content-position="center">
        {{ res.format }}<br />({{ parseInt(res.time) }}s)</el-divider
      >
      <div>预计方案:</div>
      木:
      <el-tag type="warning">{{ res.res["木"] }}</el-tag> 草:
      <el-tag type="success"> {{ res.res["草"] }}</el-tag> 铁:
      <el-tag type="info">{{ res.res["铁"] }} </el-tag>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
function formateTime(time) {
  const h = parseInt(time / 3600);
  const minute = parseInt((time / 60) % 60);
  const second = Math.ceil(time % 60);

  const hours = h < 10 ? "0" + h : h;
  const formatSecond = second > 59 ? 59 : second;
  return `${hours > 0 ? `${hours}:` : ""}${
    minute < 10 ? "0" + minute : minute
  }:${formatSecond < 10 ? "0" + formatSecond : formatSecond}`;
}

function solve(Fooder, Timber, Grass, Iron, Timber_need, DT_grass, DT_iron) {
  Timber_need = parseInt(Timber_need);
  let Iron_need = (Timber_need * 5) / 4 - Iron - DT_iron;
  let Grass_need = Timber_need * 2 - Grass - DT_grass;
  Timber_need = Timber_need - Timber;

  // 答案集
  let t = -1,
    t_res = -1,
    ans = { 铁: -1, 木: -1, 草: -1 };
  let mt_cnt = 0; // 总计算方案数

  // i,j,k
  for (let i = 0; i <= Fooder / 4; i++) {
    for (let j = 0; j <= Fooder / 4; j++) {
      if (i * 4 + j * 4 >= Fooder) break;
      let k = parseInt((Fooder - i * 4 - j * 4) / 2);
      if (k == 0) break;
      t = Iron_need / i;
      t = Math.max(t, Grass_need / j);
      t = Math.max(t, Timber_need / k);

      if (t_res == -1 || t < t_res) {
        ans["木"] = k;
        ans["草"] = j;
        ans["铁"] = i;
        t_res = t;
      }
      mt_cnt += 1;
    }
  }
  t_res *= 10;
  return { time: t_res, res: ans, format: formateTime(t_res) };
  // alert(formateTime(t_res));
}

export default {
  name: "form",
  components: {},
  data() {
    return {
      forms: ["$form"],
      dialogVisible: false,
      res: {
        res: {
          木: -1,
          草: -1,
          铁: -1,
        },
      },
      model: {
        Fooder: "",
        Timber: "",
        Grass: "",
        Iron: "",
        Timber_need: "",
        DT_grass: "",
        DT_iron: "",
      },
      rules: {
        Fooder: [
          {
            required: true,
            message: "食物劳工不能为空",
            trigger: "blur",
          },
        ],
        Timber: [
          {
            required: true,
            message: "现有木头不能为空",
            trigger: "blur",
          },
        ],
        Grass: [
          {
            required: true,
            message: "现有药草不能为空",
            trigger: "blur",
          },
        ],
        Iron: [
          {
            required: true,
            message: "现有矿石不能为空",
            trigger: "blur",
          },
        ],
        Timber_need: [
          {
            required: true,
            message: "所需木头不能为空",
            trigger: "blur",
          },
        ],
        DT_grass: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        DT_iron: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
      submit_loading: false,
    };
  },
  computed: {},
  methods: {
    submit() {
      Promise.all(this.forms.map((form) => this.$refs[form].validate())).then(
        () => {
          this.submit_loading = true;
          // TODO demo
          console.log(this.model);

          this.res = solve(
            this.model.Fooder,
            this.model.Timber,
            this.model.Grass,
            this.model.Iron,
            this.model.Timber_need,
            this.model.DT_grass,
            this.model.DT_iron
          );
          this.dialogVisible = true;
          setTimeout(() => {
            this.submit_loading = false;
          }, 3000);
        }
      );
    },
  },
  created() {},
};
</script>

<style>
</style>